const  briefcaseIcon =  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                   <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" />
                 </svg>
const phoneIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                   <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                 </svg>
const computerIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                   <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
                 </svg>
const cameraIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -300 1000 1100" xmlnsXlink="http://www.w3.org/1999/xlink">
      <path fill="#808080" d="m500 450q64 0 107-44t43-106t-44-106t-106-44t-106 44t-44 106t44 106t106 44z m400 150q42 0 71-29t29-71l0-450q0-40-29-70t-71-30l-800 0q-40 0-70 30t-30 70l0 450q0 42 30 71t70 29l120 0q28 0 40 30l30 92q10 28 40 28l340 0q30 0 40-28l30-92q12-30 40-30l120 0z m-400-550q104 0 177 73t73 177t-73 177t-177 73t-177-73t-73-177t73-177t177-73z m366 380q14 0 24 11t10 25t-10 24t-24 10q-36 0-36-34q0-16 11-26t25-10z" />
    </svg>
const targetIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
      <g id="ZnRplK">
        <path d="M98.63,36.13c2.74.42,5.55.63,8.23,1.29,23.62,5.8,38.68,20.45,45,44A52.84,52.84,0,0,1,153,102c-.44,3.41-2.94,5.44-5.94,5-3.21-.44-5-3-4.61-6.53a43,43,0,0,0-.51-14.53c-4.09-18.84-15.3-31.52-33.7-37.12-25.85-7.87-53,7.73-59.87,33.89a48.07,48.07,0,0,0,34.5,58.59,45.12,45.12,0,0,0,17.58,1.13c4.92-.7,7.51,3.59,6.3,7a5,5,0,0,1-4.28,3.54,55.49,55.49,0,0,1-26.11-2.47C57.86,144,45.44,131.38,39,112.89a51.84,51.84,0,0,1-2.66-13,9.93,9.93,0,0,0-.23-1.21V90.88c.08-.32.17-.64.22-1,.43-2.75.71-5.53,1.3-8.24A58.75,58.75,0,0,1,84.23,37.11c2.21-.39,4.43-.66,6.65-1Z"/><path d="M103.15,100.13c.25.08.74.19,1.2.36l57.44,21.88c1.62.62,2.36,1.56,2.32,2.87s-.81,2.21-2.48,2.76q-12.07,4-24.17,7.9a2.13,2.13,0,0,0-1.56,1.53q-3.85,11.92-7.78,23.82c-.1.32-.19.64-.31.95a2.73,2.73,0,0,1-2.67,1.9,2.63,2.63,0,0,1-2.57-1.83q-1.78-4.65-3.56-9.32-9.27-24.28-18.52-48.58C99.64,102.13,100.79,100.14,103.15,100.13Z"/><path d="M94.35,62.7c16.69,0,30.11,11.85,32.22,27.93.46,3.48-.87,5.92-3.67,6.76A5.24,5.24,0,0,1,116.12,93a21.1,21.1,0,0,0-5.23-12.31c-6.1-6.61-13.64-8.89-22.23-6.31S75,83,73.62,91.88A21.25,21.25,0,0,0,92.81,116.1a5.25,5.25,0,0,1,4.78,5.08,5.15,5.15,0,0,1-4,5.31,13.87,13.87,0,0,1-4.58-.2,32.06,32.06,0,0,1,2-63.35C92.26,62.78,93.56,62.75,94.35,62.7Z"/>
      </g>
    </svg>
const shareIcon = <svg
      viewBox='0 -300 1000 1100'
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink" >
      <path
        d="m650 200q62 0 106-43t44-107q0-62-44-106t-106-44t-106 44t-44 106q0 6 1 14t1 12l-260 156q-42-32-92-32q-62 0-106 44t-44 106t44 106t106 44q54 0 92-30l260 156q0 4-1 12t-1 12q0 62 44 106t106 44t106-43t44-107q0-62-44-106t-106-44q-52 0-90 32l-262-156q2-8 2-26q0-16-2-24l262-156q36 30 90 30z"
      />
    </svg>
const magnifyIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
    </svg>
const searchPlusIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.64 87.15">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="FvBPeR">
            <path d="M18.7,25.63h6.65c.28,0,.28,0,.28-.28V18.7H31c0,.08,0,.17,0,.26v6.37c0,.3,0,.3.3.3H38V31H31.31c-.3,0-.3,0-.3.3V38H25.64c0-.09,0-.18,0-.27V31.31c0-.3,0-.3-.3-.3H18.7Z"/>
            <path d="M28.32,0a28.32,28.32,0,0,0-14.4,52.71,2.29,2.29,0,0,0-.1.23L5,80.52a4.8,4.8,0,0,0,3.1,6l1.15.37a4.8,4.8,0,0,0,6-3.1l8.83-27.5A28.32,28.32,0,1,0,28.32,0Zm0,50.15A21.83,21.83,0,1,1,50.15,28.32,21.84,21.84,0,0,1,28.3,50.15Z"/>
          </g>
        </g>
      </g>
    </svg>
const pictureIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -300 1000 1100" xmlnsXlink="http://www.w3.org/1999/xlink">
      <path fill="#808080" d="m856 518l-100 0l-124 150l-214-150l-180 0q-52 0-90-39t-38-91l0-160l-108 296q-10 38 22 52l680 248q36 10 50-24z m106-90q16 0 27-12t11-28l0-472q0-16-11-28t-27-12l-724 0q-16 0-27 12t-11 28l0 472q0 16 11 28t27 12l724 0z m-56-452l0 162l-72 160l-166-60l-130-132l-138 170l-92-214l0-86l598 0z" />
    </svg>

const resizeFullIcon = <svg
      viewBox="0 0 792 1000"
      fill="currentColor"
      height="1em"
      width="1em"
    >
      <path d="M476 104h316v316L692 296 546 448 446 348l152-146-122-98M246 548l100 100-152 146 122 100H0V578l100 122 146-152" />
    </svg>

const dot3Icon = <svg
        viewBox="0 0 920 1000"
        fill="currentColor"
        height="1em"
        width="1em"
      >
        <path d="M110 390c30.667 0 56.667 10.667 78 32s32 47.333 32 78c0 29.333-10.667 55-32 77s-47.333 33-78 33-56.667-11-78-33-32-47.667-32-77c0-30.667 10.667-56.667 32-78s47.333-32 78-32m350 0c30.667 0 56.667 10.667 78 32s32 47.333 32 78c0 29.333-11 55-33 77s-47.667 33-77 33-55-11-77-33-33-47.667-33-77c0-30.667 10.667-56.667 32-78s47.333-32 78-32m350 0c30.667 0 56.667 10.667 78 32s32 47.333 32 78c0 29.333-10.667 55-32 77s-47.333 33-78 33-56.667-11-78-33-32-47.667-32-77c0-30.667 10.667-56.667 32-78s47.333-32 78-32" />
      </svg>

const rightOpenBigIcon = <svg
      viewBox="0 0 465 1000"
      fill="currentColor"
      height="1em"
      width="1em"
    >
      <path d="M13 870l358-370L13 128c-17.333-17.333-17.333-33.333 0-48 17.333-17.333 33.333-17.333 48 0l392 394c16 17.333 16 34 0 50L61 918c-14.667 17.333-30.667 17.333-48 0-17.333-14.667-17.333-30.667 0-48" />
    </svg>

const leftOpenBigIcon = <svg
      viewBox="0 0 465 1000"
      fill="currentColor"
      height="1em"
      width="1em"
    >
      <path d="M452 870c17.333 17.333 17.333 33.333 0 48-17.333 17.333-33.333 17.333-48 0L12 524c-16-16-16-32.667 0-50L404 80c14.667-17.333 30.667-17.333 48 0 17.333 14.667 17.333 30.667 0 48L94 500l358 370" />
    </svg>

  export {
    briefcaseIcon, 
    phoneIcon, 
    computerIcon,
    cameraIcon,
    targetIcon,
    shareIcon,
    magnifyIcon,
    searchPlusIcon,
    pictureIcon,
    resizeFullIcon,
    dot3Icon,
    rightOpenBigIcon,
    leftOpenBigIcon
  }
